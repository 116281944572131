<template>
  <div class="business-tax-form">
    <div class="header flex flex-row items-center py-4 justify-between">
      <h1><strong>Impuesto Fijo para Ordenes</strong></h1>
      <sa-button type="primary" class="save-button" @click="save">Guardar</sa-button>
    </div>
    <div class="w-full flex flex-row justify-between">
      <div class="content flex flex-col space-y-4">
        <div>
          <label>Nombre</label>
          <el-input v-model="tax.name" placeholder="Nombre" />
        </div>
        <div>
          <label>Porcentaje</label>
          <el-input v-model="tax.percentage" type="number" min="0" placeholder="Porcentaje" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.business-tax-form {
  max-width: 800px;
  margin: 0 auto;
}
.header {
  border-bottom: 1px solid #ccc;
}
.header h1 {
  margin: 0;
}
.content {
  margin-top: 16px;
}
</style>
<script>
export default {
  name: 'BusinessTaxForm',
  props: {
    tax: {
      type: Object,
      default: () => ({
        id: Number,
        name: String,
        percentage: Number,
      }),
    },
  },
  methods: {
    save() {
      if (this.tax.name && this.tax.percentage >= 0 && this.tax.percentage <= 100) {
        this.$emit('save', this.tax);
      }
    },
  },
}
</script>